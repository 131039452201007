import { Link } from "gatsby"
import React from "react"

import { Card, Button } from "react-bootstrap"
import { Tabs } from "../../elements"

function ProjectCard({ data }) {
    return (
        <Card style={{ width: "300px" }} className="m-3">
            <Card.Header>{data.id}</Card.Header>
            <Card.Body>
                <Card.Title>{data.title}</Card.Title>
                <Card.Text>
                    {data.status}
                    {data.availibility ? " | " + data.availibility : null}
                </Card.Text>
                <Link to={"/research/current-projects/" + data.id}>
                    <Button variant="egi">Learn More</Button>
                </Link>
            </Card.Body>
        </Card>
    )
}

export function ProjectOverview({ query }) {
    // must be in this order
    let list = {
        "EGI Oceans": [],
        "North America": [],
        "South America": [],
        "Africa": [],
        "Europe": [],
        "Asia": [],
        "Australia": [],
        "Thematic": [],
    }

    query.projects.edges.forEach(e => {
        let data = e.node.frontmatter
        list[data.region].push(<ProjectCard key={data.id} data={data} />)
    })
    const final = Object.keys(list).map(e => ({
        title: e,
        elements: list[e],
    }))
    return <Tabs data={final} type="horizontal" />
}
