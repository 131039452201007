import React from "react"
import { Page } from "../../../components/elements"
// import Map from "../../../components/elements/current-projects/google-map/GoogleMap.jsx"
import { PageCenter, DefaultHeader } from "../../../components/elements"
import { ProjectOverview } from "../../../components/elements/current-projects/ProjectOverview.jsx"
// import { ResearchList } from "../../../components/elements/current-projects/ResearchList.jsx"
import { useStaticQuery, graphql } from "gatsby"
import { Row } from "react-bootstrap"
import ExpandIcon from "../../../components/elements/research-catalogue/ExpandIcon.jsx"

export default function CurrentProjectsPage() {
    const query = useStaticQuery(
        graphql`
            {
                projects: allMarkdownRemark(
                    filter: { fileAbsolutePath: { regex: "//projects//" } }
                    sort: { fields: frontmatter___id, order: DESC }
                ) {
                    edges {
                        node {
                            ...ProjectFields
                        }
                    }
                }
                members: allMarkdownRemark(
                    filter: { fileAbsolutePath: { regex: "//members//" } }
                ) {
                    edges {
                        node {
                            ...MemberFields
                        }
                    }
                }
            }
        `,
    )

    return (
        <Page
            title="Current Projects"
            description="Active Research Projects make up the Project Portfolio. These Projects are cost-shared studies designed collaboratively between sponsoring companies and EGI scientists, providing Sponsors with the unique opportunity to shape the project and gain independent insights, interpretations, and evaluations to company-specific questions. All active projects are open for sponsorship from their launch throughout the two-year period of confidentiality and include both Regional and Thematic studies."
        >
            <PageCenter bg="white">
                <DefaultHeader title="Current Projects" type="small" />
                <a
                    href="https://egigis.maps.arcgis.com/apps/Minimalist/index.html?appid=7a4e038f831f41a187e9815eb4073b91&extent=-151.875,-45.0625,96.6797,64.3372&home=true&zoom=true&previewImage=false&scale=true&disable_scroll=true&theme=light"
                    target="new"
                    style={{
                        float: "right",
                        filter: "invert(.1)",
                        transform: "translate(-5px, 45px)",
                    }}
                >
                    <ExpandIcon />
                </a>

                <iframe
                    width="100%"
                    height="650"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    title="EGI Research Catalogue Web Map"
                    src="https://egigis.maps.arcgis.com/apps/Minimalist/index.html?appid=7a4e038f831f41a187e9815eb4073b91&extent=-151.875,-45.0625,96.6797,64.3372&home=true&zoom=true&previewImage=false&scale=true&disable_scroll=true&theme=light"
                >
                    Sorry, it looks like your browser doesn&apos;t support
                    iFrame. Try this link to view the map:
                    <a href="https://egigis.maps.arcgis.com/apps/Minimalist/index.html?appid=7a4e038f831f41a187e9815eb4073b91&extent=-151.875,-45.0625,96.6797,64.3372&home=true&zoom=true&previewImage=false&scale=true&disable_scroll=true&theme=light">
                        Project Map
                    </a>
                </iframe>
                
                <Row>
                    <p className="px-5 py-5">
                        Active Research Projects make up the Project Portfolio.
                        These Projects are cost-shared studies designed
                        collaboratively between sponsoring companies and EGI
                        scientists, providing Sponsors with the unique
                        opportunity to shape the project and gain independent
                        insights, interpretations, and evaluations to
                        company-specific questions. All active projects are open
                        for sponsorship from their launch throughout the
                        two-year period of confidentiality and include both
                        Regional and Thematic studies.
                    </p>
                </Row>
                <ProjectOverview query={query} />
            </PageCenter>
        </Page>
    )
}
